.home {
  @media (min-width: 768px) {
    height: 50vh;
  }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 48;
}

.invert {
  background-color: rgba(225, 224, 224, 0.6);
  transition: background-color 200ms, border-left 200ms, border-right 200ms;
}

.invert:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.invert:nth-child(1) {
  border-right: 1px solid transparent;
}

.invert:nth-child(1):hover {
  border-right: 1px solid black;
}

.invert:nth-child(2) {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.invert:nth-child(2):hover {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.invert:last-child {
  border-left: 1px solid transparent;
}

.invert:last-child:hover {
  border-left: 1px solid black;
}
