$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "1900x365": calc(365 / 1900 * 100),
);

@import "~mdb-react-ui-kit/dist/css/mdb.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";

@import "./fonts.scss";

#root {
  background-color: #fff !important;

}

body {
  background-color: #edecec;


  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.hontary {
  font-family: "Hontary", sans-serif;
}

.h-100 {
  height: 100%;
}

.vh-50 {
  height: 50vh;
}

.vh-100 {
  height: 100vh;
}

.bold {
  font-weight: bold;
}

input {
  &.error {
    background-color: transparentize($color: #fff, $amount: 0.25);
  }
}

button:hover {
  cursor: pointer !important;
}

.hover-invert-25:hover {
  filter: invert(0.25);
}

.hover-shadow:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.fit-content {
  width: fit-content;
}
