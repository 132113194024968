@import "~mdb-react-ui-kit/dist/scss/mdb.core.scss";

:root {
  --carousel-container-bg-color: transparent;
  --carousel-controls-bg-color: rgba(180, 180, 180, 0.2);
  --carousel-controls-arrows-bg-color: rgba(180, 180, 180, 0.7);

  @include media-breakpoint-up(sm) {
    --carousel-container-bg-color: rgba(180, 180, 180, 0.2);
  }
}

.carousel-container {
  background-color: var(--carousel-container-bg-color);
  position: relative;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .carousel-slide {
    position: absolute;
    width: 15%;
    height: 99%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    &.previous {
      left: 1%;
      display: none;
    }

    &.next {
      right: 1%;
      display: none;
    }

    &:not(.active) {
      height: 50%;
      opacity: 75%;
    }

    &.active {
      width: 100%;
      position: relative;

      &.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 100vh;
        z-index: 10;
      }
    }

    @include media-breakpoint-up(md) {
      &.previous,
      &.next {
        display: flex !important;
      }

      &.active {
        width: 50%;
      }
    }
  }

  .carousel-controls {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;

    &.fullscreen {
      position: fixed;
      z-index: 11;
    }

    button {
      border: 0;
      padding: 3px;

      background-color: var(--carousel-controls-bg-color);

      &:hover {
        filter: invert(1);
      }
    }

    @include media-breakpoint-up(sm) {
      flex-direction: column;
    }
  }

  .carousel-controls-arrows {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent !important;

    button {
      border: 0 !important;
      background-color: var(--carousel-controls-arrows-bg-color);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        filter: invert(1);
        background-color: var(--carousel-container-bg-color);
      }

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }
  }
}

@keyframes slideout {
  from {
    height: 0;
  }

  to {
    height: 20px;
  }
}

.carousel-thumbnails-container {
  overflow: hidden;

  .carousel-selector-item {
    opacity: 60%;

    &:hover {
      cursor: pointer !important;
    }

    &.active {
      position: relative;
      background-image: linear-gradient(131.83deg, #fffafa 0%, #3e3e3e 99.21%);
      opacity: 100%;
    }

    .photo-thumbnail-controls {
      &.bg-light {
        --mdb--bg-opacity: 0.7;
      }

      button {
        --mdb-btn-padding-top: 0;
        --mdb-btn-padding-bottom: 0;
        --mdb-btn-padding-x: 0.2rem;
        --mdb-btn-border-radius: 0;
      }
    }
  }
}
