.album-thumbnail {
  transform: scale(1);
  transition: transform 200ms;

  &:hover {
    transform: scale(0.98);
  }

  .link {
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 200ms;

    &:hover {
      background-color: rgba(255, 255, 255, 60%);
    }

    * {
      background-color: hsl(0deg 0% 100% / 60%);
    }
  }
}
